import React, { useRef, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby';

import logo from '../images/home/ae.svg'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import lottie from 'lottie-web';
import contactData from '../images/home/contact_animation.json'
import octopusRobotData from '../images/home/octopus_animation.json'
import developData from '../images/home/develop.json'

import "../styles/main.scss"


const IndexPage = () => {

	let contactAnimRef = useRef(null);
	let developAnimRef = useRef(null);
	let octRef = useRef(null);


	useEffect(() => {
		 const contactAnim = lottie.loadAnimation({
			container: contactAnimRef.current,
			renderer: "svg",
			loop: true,
			autoplay: true,
			animationData: contactData
		});

		const octopusRobotAnim = lottie.loadAnimation({
			container: octRef.current,
			renderer: "svg",
			loop: true,
			autoplay: true,
			animationData: octopusRobotData
			});

		const developAnim = lottie.loadAnimation({
			container: developAnimRef.current,
			renderer: "svg",
			loop: true,
			autoplay: true,
			animationData: developData
		});

		return () =>  {
			developAnim.destroy();
			contactAnim.destroy();
			octopusRobotAnim.destroy();
		} // optional clean up for unmounting
	}, []);



	const data = useStaticQuery(graphql`
		query {
			design_robot: file(relativePath: { eq: "home/design_robot.png" }) {
				childImageSharp {
					fluid(maxWidth: 400, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			growth: file(relativePath: { eq: "home/growth.png" }) {
				childImageSharp {
					fluid(maxWidth: 700, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	`)


	return(
		<Layout>
			<SEO title="Home" />
			<div className='home-section home-intro'>
				<div className="content">
					<div className="brand">
						<img src={logo} alt='Axe Edge logo' />
					</div>
				</div>
			</div>
			<div className='home-section bg-primary'>
				<div className="content">
					<div className="illustation">
						<div ref={octRef}></div>
						{/* <Img fluid={data.octopus_robot.childImageSharp.fluid} /> */}
					</div>
					<div className="panel bg-white text-primary">
						<h1 className='display title'>Hi</h1>
						<p>We are a small but dedicated team that can help you turn your idea into reality.</p>
					</div>
				</div>
			</div>

			<div className='home-section'>
				<div className="content">
					<div className="illustation">
						<Img style={{maxWidth:'400px'}} fluid={data.design_robot.childImageSharp.fluid} />
					</div>
					<div className="panel bg-primary">
						<h2 className='title display'>_design</h2>
						<p>From a brand to marketing site to a full app. We help not just with how it looks but with how it works.</p>
					</div>
				</div>
			</div>

			<div className='home-section bg-primary'>
				<div className="content">
					<div className="illustation">
						<div ref={developAnimRef}></div>
						{/* <Img style={{maxWidth:'400px'}} fluid={data.develop_robots.childImageSharp.fluid} />  */}
					</div>
					<div className="panel bg-white text-primary">
						<h2 className='title display'>_develop</h2>
						<p>We'll quickly and efficiently get your idea to market using modern, scaleable code.</p>
					</div>
				</div>
			</div>

			<div className='home-section'>
				<div className="content">
					<div className="illustation">
						<Img fluid={data.growth.childImageSharp.fluid} />
					</div>
					<div className="panel bg-primary">
						<h2 className='title display'>_grow</h2>
						<p>Now it's time to expand and grow. We’re in for the long haul and can help you nurture your app into the success it deserves.</p>
					</div>
				</div>
			</div>

			<div className='home-section bg-primary'>
				<div className="content">
					<div className="illustation">
						<div ref={contactAnimRef}></div>
						{/* <Img style={{maxWidth:'400px'}} fluid={data.contact_robots.childImageSharp.fluid} /> */}
					</div>
					<div className="panel panel-contact bg-white text-primary">
						<h2 className='title display'>say hi back</h2>
						<Link to='/contact' className='btn mb-3 btn-large px-4'>Say hi...</Link>
						<p>Drop us a line, we promise we don’t bite and we won’t hit you with a sales pitch*</p>
						<p className="small">* well, maybe just a little bit!</p>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default IndexPage
